import gsap from "gsap";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import '../../assets/img/experimental/fonts/webfonts/syne/stylesheet.css';
import heroimg1 from '../../assets/img/icons/herosection/8.webp';

const Hero = () => {
    useEffect(() => {
        // Preload the LCP image
        const link = document.createElement('link');
        link.rel = 'preload';
        link.href = heroimg1; // Use the imported image
        link.as = 'image';
        document.head.appendChild(link);

        const tl = gsap.timeline();
        tl.set("#animate_heading", { y: 50, opacity: 0 });

        tl.to("#animate_heading", {
            y: 0,
            opacity: 1,
            duration: 1.5,
            ease: "power1",
        });
    }, []);

    return (
        <section class="section-hero">
            <div class="relative z-10 overflow-hidden bg-[#FAF9F5]">
                <div class="pb-[60px] pt-28 md:pb-20 md:pt-36 lg:pb-[100px] lg:pt-[150px] xxl:pb-[15vh] xxl:pt-[19vh]">
                    <div class="container-custom">
                        <div class="grid gap-10 lg:grid-cols-2 xxl:grid-cols-[1.1fr_minmax(0,_1fr)]">
                            <div class="xl:max-w-xl xxl:max-w-[700px]" id="animate_heading">
                                <h1 class="mb-6 font-Syne font-semibold leading-none -tracking-[2px] text-[#52818b] lg:text-[50px] xl:text-[60px] xxl:text-[70px] text-[35px] md:text-[40px]">
                                    Unlock Your Business Potential with Advanced IT Solutions
                                </h1>
                                <p class="mb-8 text-[#252525] lg:mb-[50px] font-Syne font-semibold">
                                    We provide cutting-edge IT services and solutions to optimize your business processes, enhance productivity, and drive digital transformation. Our team delivers tailored technology strategies to meet your unique needs and stay ahead in today's competitive market.
                                </p>
                                <Link to={'/contact'} class="btn bg-[#52818b] text-white hover:text-[#52818b] hover:border hover:border-[#52818b] border is-large is-rounded btn-s group inline-block btn-animation"><span>Lets connect</span></Link>
                            </div>
                            <div class="jos">
                                <div class="relative flex items-center justify-center">
                                    <img 
                                        src={heroimg1} 
                                        alt="Hero section illustration" 
                                        class="h-auto w-full max-w-full max-h-[579px]" 
                                        id="animate_heading" 
                                        loading="eager" // Ensure the image loads as soon as possible
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Hero;