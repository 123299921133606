import React, { useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import CodeLamdaLogo from "../../assets/img/logos/black.png";
import "../../style.css";
import Footer from "./Footer";

const Navbar = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const checkMobileView = () => {
      setIsMobileView(window.innerWidth < 992);
    };

    checkMobileView();
    window.addEventListener("resize", checkMobileView);

    return () => {
      window.removeEventListener("resize", checkMobileView);
    };
  }, []);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        isMenuOpen &&
        !event.target.closest(".menu-block-wrapper") &&
        !event.target.closest(".hamburger-menu")
      ) {
        setMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isMenuOpen]);

  const [isScrolled, setIsScrolled] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <header
        className={`site-header site-header--absolute fixed py-1 transition ease-in-out duration-500 ${
          isScrolled ? "bg-[#F7F7F7]/95 shadow-xl" : "bg-transparent"
        }`}
        id="sticky-menu"
      >
        <div className="container-default">
          <div className="flex items-center justify-between gap-x-7">
            <Link to={"/"} className="flex-shrink-0">
              <img
                src={CodeLamdaLogo}
                alt="logo"
                width="140px"
                className="sm:w-[200px] py-2 transition-all duration-300"
              />
            </Link>

            <div className="flex items-center justify-between w-full">
              {!isMobileView && (
                <nav className="menu-block ml-auto" id="append-menu-header">
                  <ul
                    className="site-menu-main flex justify-end"
                    style={{ columnGap: "35px" }}
                  >
                    <li className="nav-item">
                      <Link to={"/"} className="nav-link-item drop-trigger">
                        <p className="font-Syne text-xl hover:text-[#53818B]">
                          Home
                        </p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to={"/service"}
                        className="nav-link-item drop-trigger"
                      >
                        <p className="font-Syne text-xl hover:text-[#53818B]">
                          Services
                        </p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to={"/about"} className="nav-link-item">
                        <p className="font-Syne text-xl hover:text-[#53818B]">
                          About
                        </p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to={"/contact"}
                        className="nav-link-item drop-trigger"
                      >
                        <p className="font-Syne text-xl hover:text-[#53818B]">
                          Contact
                        </p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to={"/blogs"}
                        className="nav-link-item drop-trigger"
                      >
                        <p className="font-Syne text-xl hover:text-[#53818B]">
                          Blogs
                        </p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to={"/career"}
                        className="nav-link-item drop-trigger"
                      >
                        <p className="font-Syne text-xl hover:text-[#53818B]">
                          Career
                        </p>
                      </Link>
                    </li>
                  </ul>
                </nav>
              )}
            </div>

            <div className="flex items-center gap-6">
              <div className="block lg:hidden">
                <button
                  id="openBtn"
                  className="hamburger-menu mobile-menu-trigger"
                  onClick={toggleMenu}
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </button>
              </div>

              {isMobileView && (
                <div
                  className={`menu-block-wrapper ${isMenuOpen ? "open" : ""}`}
                  style={{
                    backgroundColor: "rgba(255, 255, 255, 0.3)",
                    backdropFilter: "blur(10px)",
                  }}
                >
                  <ul className="site-menu-main">
                    <li className="nav-item">
                      <Link
                        to={"/"}
                        className="nav-link-item"
                        style={{ padding: "0px" }}
                        onClick={toggleMenu}
                      >
                        Home
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to={"/service"}
                        className="nav-link-item"
                        style={{ padding: "0px" }}
                        onClick={toggleMenu}
                      >
                        Services
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to={"/blogs"}
                        className="nav-link-item"
                        style={{ padding: "0px" }}
                        onClick={toggleMenu}
                      >
                        Blogs
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to={"/about"}
                        className="nav-link-item"
                        style={{ padding: "0px" }}
                        onClick={toggleMenu}
                      >
                        About
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to={"/contact"}
                        className="nav-link-item"
                        style={{ padding: "0px" }}
                        onClick={toggleMenu}
                      >
                        Contact
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to={"/career"}
                        className="nav-link-item"
                        style={{ padding: "0px" }}
                        onClick={toggleMenu}
                      >
                        Career
                      </Link>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </header>

      <Outlet />
      <Footer />
    </>
  );
};

export default Navbar;