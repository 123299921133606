import { useEffect, useState } from "react";
import axios from 'axios';
import { toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const CareerForm = (props) => {
  const [resume, setResume] = useState(null);
  const [resumeName, setResumeName] = useState('');
  const [job_id, setJobId] = useState('');

  useEffect(() => {
    setJobId(props.data);
  }, [props.data]);

  const handleApply = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('name', e.target.name.value);
    formData.append('email', e.target.email.value);
    formData.append('phone', e.target.phone.value);
    formData.append('resume', resume);
    formData.append('message', e.target.message.value);
    formData.append('job_id', job_id);

    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}application/applyfornewjob`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log(response.data);
      toast.success(`${response.data.message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
      });
      e.target.reset();
      setResume(null);
      setResumeName('');
    } catch (error) {
      toast.error(`${error.response.data.message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
        });
      console.error("Error submitting form:", error);
    }
  }

  return (
    <div className="career-form-container p-10 rounded-lg border border-gray-300 bg-white shadow-lg">
      <h2 className="text-2xl font-bold mb-6">Apply for this Position</h2>
      <form onSubmit={handleApply} className="space-y-6">
        <div>
          <label htmlFor="name" className="block text-sm font-medium text-gray-700">
            Your Name
          </label>
          <input
            type="text"
            name="name"
            id="name"
            placeholder="Enter your full name"
            className="mt-1 block w-full rounded-md border border-gray-300 px-4 py-2"
            required
          />
        </div>

        <div>
          <label htmlFor="email" className="block text-sm font-medium text-gray-700">
            Email Address
          </label>
          <input
            type="email"
            name="email"
            id="email"
            placeholder="Enter your email"
            className="mt-1 block w-full rounded-md border border-gray-300 px-4 py-2"
            required
          />
        </div>

        <div>
          <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
            Phone Number
          </label>
          <input
            type="tel"
            name="phone"
            id="phone"
            placeholder="Enter your phone number"
            className="mt-1 block w-full rounded-md border border-gray-300 px-4 py-2"
            required
            maxLength="10"
            pattern="\d{10}"
            title="Please enter a 10-digit phone number"
          />
        </div>

        <div>
          <div className="flex items-center justify-center w-full">
            <label htmlFor="dropzone-file" className="flex flex-col items-center justify-center w-full h-32 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50">
              <div className="flex flex-col items-center justify-center pt-5 pb-6">
                <svg class="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                </svg>
                <p className="mb-2 text-sm text-gray-500">
                  <span className="font-semibold">Click to upload Resume</span>
                </p>
                <p className="text-xs text-gray-500">.PDF only</p>
                {resumeName && <p className="text-sm text-gray-600">Uploaded: {resumeName}</p>}
              </div>
              <input
                id="dropzone-file"
                type="file"
                accept=".pdf"
                className="hidden"
                required
                onChange={(e) => {
                  setResume(e.target.files[0]);
                  setResumeName(e.target.files[0].name);
                }}
              />
            </label>
          </div>
        </div>

        <div>
          <label htmlFor="message" className="block text-sm font-medium text-gray-700">
            Write Your Message
          </label>
          <textarea
            name="message"
            id="message"
            placeholder="Write us your question here..."
            className="mt-1 block w-full rounded-md border border-gray-300 px-4 py-2"
            rows="2"
          ></textarea>
        </div>

        <button
          type="submit"
          className="w-full bg-[#52818B] text-white font-semibold py-2 rounded-md hover:bg-[#52818B]/90 transition duration-200"
        >
          Apply
        </button>
      </form>
    </div>
  )
}

export default CareerForm;