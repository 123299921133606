import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import abouticon1 from '../../assets/img/icons/about/aboutus_icon1.webp';
import abouticon2 from '../../assets/img/icons/about/aboutus_icon2.webp';
import abouticon3 from '../../assets/img/icons/about/aboutus_icon3.webp';
import abouticon4 from '../../assets/img/icons/about/aboutus_icon4.webp';
import abouticon5 from '../../assets/img/icons/about/aboutus_icon5.webp';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const About = () => {
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const animateElements = (selector, yStart, delay) => {
      gsap.set(selector, { y: yStart, opacity: 0 });
      gsap.to(selector, {
        scrollTrigger: selector,
        y: 0,
        opacity: 1,
        start: "top center",
        duration: 1.5,
        delay,
        ease: "power2.out",
      });
    };

    animateElements("#aboutusheading", 30, 0.2);
    animateElements("#section1_card", 50, 0.2);
  }, []);

  const benefitCards = [
    { icon: abouticon4, title: "Uncompromising Quality Assurance", description: "Experience excellence with our unwavering commitment to quality. Our rigorous QA processes ensure top-tier software solutions that surpass industry standards and client expectations." },
    { icon: abouticon5, title: "Swift and Reliable Delivery", description: "Time is money in the digital world. Our agile methodologies and efficient project management guarantee on-time delivery without sacrificing the superior quality of our custom software solutions." },
    { icon: abouticon3, title: "Client-Centric Approach", description: "Your success is our priority. We offer tailored software development services, addressing your unique business challenges and objectives to foster enduring partnerships and drive your digital growth." },
    { icon: abouticon1, title: "Passionate Innovation", description: "Fueled by passion for cutting-edge technology, we infuse creativity and expertise into every project. Our enthusiasm translates into innovative, high-performance software solutions that propel your business forward." },
    { icon: abouticon2, title: "Collaborative Creativity", description: "Harness the power of our diverse, skilled team. We combine creative thinking with technical prowess to deliver groundbreaking software solutions, pushing the boundaries of what's possible in digital innovation." },
  ];

  return (
    <section className="section-features bg-[#F7F7F7] pb-8">
      <ScrollToTop />
      <div className="container-default">
        <div className="mx-auto mb-12 pt-12">
          <h2 className="text-center text-4xl relative z-[10] font-Syne font-semibold -tracking-[0.9px]" id='aboutusheading'>
            Benefits of Choosing <span className='text-[#53818B]'>CodeLamda</span> Technologies
          </h2>
        </div>
        <div className="relative z-10 flex flex-wrap justify-center gap-10">
          {benefitCards.map((card, index) => (
            <div key={index} className="flex-shrink-0 w-full sm:w-[80%] md:w-[40%] lg:w-[30%] xl:w-[30%] xxl:w-[30%] rounded-lg" id='section1_card'>
              <div className="bg-white p-6 rounded-lg shadow-lg text-center hover:bg-[#eafaf8] transition duration-300 ease-in-out hover:scale-105 h-full flex flex-col">
                <img src={card.icon} alt={`${card.title} icon`} className="mx-auto mb-4 h-16 w-16" />
                <h3 className="text-2xl font-semibold mb-2 font-Syne">{card.title}</h3>
                <p className="flex-grow">{card.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default About;