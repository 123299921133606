import React, { useEffect } from "react";
import shape1 from "../../assets/img/experimental/home-9-bg-shape-1.svg";

import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import HomeFslider from "../Feedback/HomeFslider";

const Feedback = () => {
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    gsap.set("#feedbackheading", { y: 50, opacity: 0 });
    gsap.set("#feedbackcard", { y: 50, opacity: 0 });

    gsap.to("#feedbackheading", {
      scrollTrigger: "#feedbackheading",
      y: 0,
      opacity: 1,
      start: "top center",
      duration: 1.5,
      delay: 0.2,
      ease: "power2.out",
    });
    gsap.to("#feedbackcard", {
      scrollTrigger: "#feedbackcard",
      y: 0,
      opacity: 1,
      start: "top center",
      duration: 1.5,
      delay: 0.2,
      ease: "power2.out",
    });
  }, []);

  const boxShadowStyle = {
    boxShadow: `
     0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 -10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 5px -2px rgba(0, 0, 0, 0.1)
        `,
  };
  return (
    <section
      className="section-testimonial bg-[#F7F7F7] pb-10"
      style={boxShadowStyle}
    >
      <div className="relative z-10">
        <div className="section-space">
          <div className="container-default">
            <div className="jos mb-[60px] xl:mb-20">
              <div className="mx-auto max-w-[636px]">
                <h2
                  class="mb-5 text-center font-Syne text-4xl font-semibold leading-[1.14] -tracking-[1px] text-ColorOil lg:text-5xl xl:text-[56px]"
                  id="feedbackheading"
                >
                  Client Testimonials
                </h2>
              </div>
            </div>
            <HomeFslider />
          </div>
        </div>
        <div class="absolute left-0 top-0 -z-10 sm:opacity-30 md:opacity-50 lg:opacity-100">
          <img
            className="opacity-30 sm:opacity-50 lg:opacity-100"
            src={shape1}
            alt="home-9-bg-shape-1"
            width="345"
            height="337"
          />
        </div>
      </div>
    </section>
  );
};

export default Feedback;