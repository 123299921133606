import React, { useEffect, useState } from 'react'
import { useParams, Link } from 'react-router-dom';
import medium from '@giuseppecampanelli/medium-api';
import { Helmet } from 'react-helmet';

const BlogDetails = () => {
  const { source, id } = useParams();
  const [blogDetails, setBlogDetails] = useState(null)
  const [loading, setLoading] = useState(true)
  const [metadata, setmetadata] = useState({})

  useEffect(() => {
    const fetchBlogData = async () => {
      try {
        setLoading(true)
        let selectedBlog;

        if (source === 'medium') {
          const posts = await medium.profile.getRecentPosts('ladumorrahul');
          selectedBlog = posts.find(blog => blog.guid.includes(id));

          if (selectedBlog) {
            setBlogDetails({
              title: selectedBlog.title,
              description: selectedBlog.content,
              images: selectedBlog.thumbnail ? [selectedBlog.thumbnail] : [],
              pubDate: new Date(selectedBlog.pubDate).toLocaleDateString(),
              author: selectedBlog.author,
              categories: selectedBlog.categories,
            });
          }
        }

        setLoading(false)
      } catch (error) {
        console.error('Error fetching blog data:', error);
        setLoading(false)
      }
    };
    fetchBlogData();
  }, [source, id])


useEffect(()=>{
  console.log(blogDetails)
  let finalDescription = blogDetails?.description;
  const extractImageSrc = (htmlString) => {
    const imgSrcMatch = htmlString?.match(/<img[^>]+src="([^">]+)"/);
    return imgSrcMatch ? imgSrcMatch[1] : null;
};

  if (typeof DOMParser !== 'undefined') {
      const parser = new DOMParser();
      const doc = parser.parseFromString(blogDetails?.description, 'text/html');
      const paragraphs = doc.querySelectorAll('p');

      if (paragraphs.length > 0) {
          finalDescription = paragraphs[0].textContent;
          setmetadata({
            seotitle: blogDetails?.title,
            seodescription:finalDescription,
            seokeywords:blogDetails?.categories,
            seocoverimage: extractImageSrc(blogDetails?.description)
          });
          console.log("is",metadata)
        } else {
          finalDescription = doc.body.textContent;
          setmetadata({
            seotitle: blogDetails?.title,
            seodescription:finalDescription,
            seokeywords:blogDetails?.categories,
            seocoverimage: extractImageSrc(blogDetails?.description)
          });
          console.log("is",metadata)
      }
  }
},[blogDetails])

  return (
    <>
{/* 
      <Helmet>
                <title>{metadata.seotitle}</title>
                <meta name="description" content={metadata.seodescription} />
                <meta name="keywords" content={metadata.seokeywords} />
                <meta property="og:title" content={metadata.seotitle} />
                <meta property="og:description" content={metadata.seodescription} />
                <meta property="og:image" content={metadata.seocoverimage} />
                <meta property="og:url" content={`https://www.codelamda.com/blog/${source}/${id}`} />
            </Helmet> */}


      <style>
        {`
.blog-content-medium h1, .blog-content-medium h2,
.blog-content-medium h3, .blog-content-medium h4,
.blog-content-medium h5, .blog-content-medium h6 {
  color: #2c3e50;
  margin-top: 30px;
  margin-bottom: 15px;
}

.blog-content-medium h1 { font-size: 2.5em; }
.blog-content-medium h2 { font-size: 2em; }
.blog-content-medium h3 { font-size: 1.75em; }
.blog-content-medium h4 { font-size: 1.5em; }
.blog-content-medium h5 { font-size: 1.25em; }
.blog-content-medium h6 { font-size: 1em; }

.blog-content-medium p {
  margin-bottom: 15px;
}

.blog-content-medium figure {
  margin: 20px 0;
  text-align: center;
}

.blog-content-medium img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}
..blog-content-medium ol{
  margin-bottom: 15px;
  padding-left: 30px;
  list-style-type: decimal;

}
.blog-content-medium ul, .blog-content-medium ol {
  margin-bottom: 15px;
  padding-left: 30px;
  list-style-type: disc;
}

.blog-content-medium li {
  margin-bottom: 10px;
  display: list-item;
}

.blog-content-medium a {
  color: #3498db;
  text-decoration: none;
}

.blog-content-medium a:hover {
  text-decoration: underline;
}

.blog-content-medium blockquote {
  border-left: 4px solid #3498db;
  padding-left: 15px;
  margin-left: 0;
  font-style: italic;
  color: #555;
}

.blog-content-medium code {
  background-color: #2d2d2d;
  padding: 2px 6px;
  border-radius: 4px;
  font-family: 'Consolas', 'Monaco', 'Andale Mono', 'Ubuntu Mono', monospace;
  font-size: 0.9em;
  color: #e6e6e6;
}

.blog-content-medium pre {
  background-color: #1e1e1e;
  padding: 15px;
  border-radius: 8px;
  overflow-x: auto;
  margin-bottom: 15px;
  border: 1px solid #3a3a3a;
  color:white;
  }

.blog-content-medium pre code {
  background-color: transparent;
  padding: 0;
  border-radius: 0;
  font-size: 0.95em;
  color: white;
  display: block;
  line-height: 1.5;
}

.blog-content-medium hr {
  border: none;
  border-top: 1px solid #e0e0e0;
  margin: 30px 0;
}

.blog-content-medium table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 15px;
}

.blog-content-medium th, .blog-content-medium td {
  border: 1px solid #e0e0e0;
  padding: 8px;
  text-align: left;
}

.blog-content-medium th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.blog-content-medium strong {
  font-weight: bold;
}

.blog-content-medium em {
  font-style: italic;
}

.blog-content-medium sup, .blog-content-medium sub {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

.blog-content-medium sup { top: -0.5em; }
.blog-content-medium sub { bottom: -0.25em; }

.blog-content-medium dl {
  margin-bottom: 15px;
}

.blog-content-medium dt {
  font-weight: bold;
}

.blog-content-medium dd {
  margin-left: 15px;
  margin-bottom: 10px;
}
.highlight__panel{
      display: none;
}
        `}
      </style>
      {
        loading ? (<span className="loading loading-dots loading-lg"></span>) :
          <div className="blog-section">

            <div className="container-default bg-transparent max-w-full px-4 md:px-6 lg:px-8 pt-[13%] sm:pt-[5%]">
              <div className="flex flex-col gap-y-10 lg:gap-y-14 xl:gap-y-20 max-w-6xl mx-auto">
                <div className="flex flex-col gap-6">
                  <article className="jos overflow-hidden bg-transparent">
                    <div className="px-[2vw] md:px-[30px] relative z-[11]">
                      <div>
                        <div className='mb-5 mt-[5vh] flex flex-col'>
                          <h2 className="line-clamp-2 font-body text-2xl font-bold leading-[1.4] -tracking-[1px] group-hover:text-ColorBlue lg:text-3xl">
                            {blogDetails?.title}
                          </h2>
                          <p className="text-sm text-gray-500 mt-2">
                            Published on {blogDetails?.pubDate}
                          </p>
                          <div className="flex flex-wrap gap-2 mt-2">
                            {blogDetails?.categories?.map((category, index) => (
                              <span key={index} className="bg-[#52818B] text-white rounded-full px-3 py-1 text-sm">
                                #{category}
                              </span>
                            ))}
                          </div>
                        </div>
                        <div className="">
                          {
                            blogDetails?.images?.map((image, index) => (
                              <img key={index} src={image} alt={`blog-inner-img-${index + 1}`} style={{ maxWidth: '100%', height: 'auto' }} />
                            ))
                          }
                        </div>
                        <div className='blog-content-medium pb-[100px]' dangerouslySetInnerHTML={{ __html: blogDetails?.description }} />
                        <div class="flex justify-center mt-16 mb-8" id="button">
                          <Link
                            to="/blogs"
                            class="group relative inline-flex items-center justify-center px-8 py-4 text-lg font-bold text-white transition-all duration-300 ease-in-out bg-[#52818B] rounded-full overflow-hidden shadow-lg hover:shadow-xl"
                          >
                            <span class="absolute inset-0 w-full h-full bg-[#52818B] opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out"></span>
                            <span class="relative z-10 flex items-center">
                              Surf Other Articles
                              <svg class="w-6 h-6 ml-2 transform group-hover:translate-x-1 transition-transform duration-300" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 7l5 5m0 0l-5 5m5-5H6"></path>
                              </svg>
                            </span>
                            <span class="absolute bottom-0 left-0 w-full h-1 bg-white transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300 ease-in-out"></span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
              </div>
            </div>
          </div>
      }
    </>
  )
}

export default BlogDetails